import { useAuthParamAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthParamAPI';
import CardWidget from '@whitecobalt/tungsten/esm/components/CardWidget';
import React, { useEffect } from 'react'
import './index.scss'

interface CreditBalanceProps extends React.HTMLProps<HTMLDivElement> {
    organisationID: any;
}

const CreditBalance: React.FunctionComponent<CreditBalanceProps> = ({organisationID}) => {

    const requestGetCurrentBalance = useAuthParamAPI('/api/Credit/balance/{id}', { method: 'GET'})

    const handleClick = () => {
        requestGetCurrentBalance.call({id: organisationID})
    }

    useEffect(handleClick, [])

    return (
        <div className="credit-balance" onClick={handleClick} title="Refresh">
            <CardWidget 
                title="Credit Balance"
                icon="fa fa-credit-card"
                backgroundColor="#1495a5"
                loading={requestGetCurrentBalance.loading}
                >
                {(requestGetCurrentBalance.response?.creditBalance || 0).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
            </CardWidget>
        </div>
    )
}

export default CreditBalance
