/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import FormManager from "@whitecobalt/tungsten/FormManager";
import Toaster from "@whitecobalt/tungsten/Toaster";
import { FormEvent, useForm } from "formydable";
import Spinner from 'react-bootstrap/Spinner';
import OTP from "@whitecobalt/tungsten/Fields/OTP";
import { useScreenTitle } from "@whitecobalt/tungsten/hooks/useScreenTitle";
import { toAbsoluteUrl } from "@whitecobalt/tungsten/utils/assets";
import branding from "@config/branding";
import { useAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAPI";
import AuthLogo from "@components/AuthLogo";

const ConfirmEmailByCode: React.FunctionComponent = () => {
    useScreenTitle(`Confirm Email - ${branding.brandName}`)
    const history = useHistory();
    const location = useLocation<any>();
    const requestConfirmEmail = useAPI('', { method: 'POST' })
    const formMethods = useForm()
    const confirmCode = formMethods.formState('confirmCode').value
    const submitRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if(confirmCode.length === 6) {
            submitRef.current?.click()
        }
    }, [confirmCode])

    useEffect(() => {
        if (!location.state?.token) {
            history.push("/auth/login");
        }
        
        handleClickAroundOTPField()
    }, []);

    const handleClickAroundOTPField = () => {
        document.querySelector<HTMLDivElement>('.otp-field')?.click()
    }

    const handleSubmit = (event: FormEvent) => {
        if (event.isReady()) {
            const FORM_DATA = event.json();

            requestConfirmEmail
                .call({
                    url: `/api/ConfirmEmail/code?ConfirmationCode=${FORM_DATA.confirmCode}`,
                    headers: {
                        "Authorization": `Bearer ${location.state?.token}`
                    }
                })
                .then((response) => {
                    if (response.data.success) {
                        Toaster.success("Code has been verified");
                    } else {
                        event.resetForm();
                        handleClickAroundOTPField();
                        requestConfirmEmail.setError(response.data.message);
                    }
                })
                .catch((error) => {
                    const errorMessage = error?.response?.data?.message || branding.messages.error
                    requestConfirmEmail.setError(errorMessage);
                });
        }
    };

    return (
        <div className="login-form login-signin" id="kt_login_signin_form" onClick={handleClickAroundOTPField}>
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <div className="mb-10" data-env-impose="true">
                    <AuthLogo />
                </div>
                <h3 className="font-size-h1">Confirm Email</h3>
                <p className="text-muted font-weight-bold">
                    Please enter the code sent to your email address
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <FormManager.Core
                value={formMethods}
                className="form fv-plugins-bootstrap fv-plugins-framework"
                onSubmit={handleSubmit}>
                {!!requestConfirmEmail.error && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {requestConfirmEmail.error}
                        </div>
                    </div>
                )}
                <FormManager.Input
                    as={OTP}
                    type="text"
                    name="confirmCode"
                    codeLength={6}
                    formGroupClassName="fv-plugins-icon-container"
                    />
                <div className="form-group d-flex flex-wrap justify-content-between">
                    <button
                        ref={submitRef}
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={requestConfirmEmail.loading}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 d-flex align-items-center">
                        <span>Verify</span>
                        {requestConfirmEmail.loading && (
                            <Spinner animation="border" className="ml-3 spinner-white" size="sm" />
                        )}
                    </button>
                    <Link to="/auth">
                        <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3">
                            Cancel
                        </button>
                    </Link>
                </div>
            </FormManager.Core>
            {/*end::Form*/}
        </div>
    );
}

export default ConfirmEmailByCode;
