import { gql } from "@apollo/client";

export type DropdownQueryType<P = {
  value: number;
  label: string;
}> = {
  dropdown: {
    items: P[];
  };
}


export const FontAwesomeIconsDropdownQuery = gql`
query FontAwesomeIconsDropdownQuery($where: FontAwesomeIconFilterInput) {
    dropdown: FontAwesomeIcons(where: $where) {
      items {
        value: CSSClass
        label: Description
      }
    }
  }
`;

export const ColourPalettesDropdownQuery = gql`
query ColourPalettesDropdownQuery($where: ColourPaletteFilterInput) {
    dropdown: ColourPalettes(where: $where) {
      items {
        value: HexCode
        label: Description
      }
    }
  }
`;

export const OrganisationsDropdownQuery = gql`
query OrganisationsDropdownQuery($where: OrganisationFilterInput) {
    dropdown: Organisations(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const OrganisationsByTypeDropdownQuery = gql`
query OrganisationsByTypeDropdownQuery(
  $where: OrganisationFilterInput, 
  $organisationTypeID: Int!
  ) {
    dropdown: OrganisationsByType(
      where: $where, 
      OrganisationTypeID: $organisationTypeID
    ) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const SentryProjectDropdownQuery = gql`
query SentryProjectDropdownQuery($where: SentryProjectFilterInput) {
    dropdown: SentryProjects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const OrganisationTypesDropdownQuery = gql`
  query OrganisationTypesDropdownQuery{
    dropdown: OrganisationTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const ParameterTypesDropdownQuery = gql`
  query ParameterTypesDropdownQuery{
    dropdown: ParameterTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PermissionsDropdownQuery = gql`
  query PermissionsDropdownQuery($where: PermissionFilterInput) {
    dropdown: Permissions(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const JobRolesDropdownQuery = gql`
  query JobRolesDropdownQuery{
    dropdown: JobRoles(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UserTypesDropdownQuery = gql`
  query UserTypesDropdownQuery{
    dropdown: UserTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CountriesDropdownQuery = gql`
  query CountriesDropdownQuery{
    dropdown: Countries {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MobileCountriesDropdownQuery = gql`
  query MobileCountriesDropdownQuery($order: [CountrySortInput!]) {
    dropdown: Countries(order: $order) {
      items {
        value: ID
        label: PhonePrefix
      }
    }
  }
`;

export const TitlesDropdownQuery = gql`
  query TitlesDropdownQuery{
    dropdown: Titles {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmailTemplatesDropdownQuery = gql`
  query EmailTemplatesDropdownQuery($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;

export const DocumentTemplatesDropdownQuery = gql`
  query DocumentTemplatesDropdownQuery($where: DocumentTemplateFilterInput) {
    dropdown: DocumentTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;


export const ApplicationAreasDropdownQuery = gql`
  query ApplicationAreasDropdownQuery{
    dropdown: ApplicationAreas(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersDropdownQuery = gql`
  query UsersDropdownQuery($where: UserFilterInput) {
    dropdown: Users(where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const CompanyUsersDropdownQuery = gql`
  query CompanyUsersDropdownQuery($id: Int!, $where: UserFilterInput) {
    dropdown: UsersInOrganisation(iD: $id, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const UsersByUserTypesDropdownQuery = gql`
  query UsersByUserTypesDropdownQuery($userTypeIDs: [Int!], $where: UserFilterInput) {
    dropdown: UsersByUserTypes(userTypeIDs: $userTypeIDs, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AccountSystemContactsDropdownQuery = gql`
  query AccountSystemContactsDropdownQuery($where: AccountSystemContactFilterInput) {  
    dropdown: AccountSystemContacts(where: $where) {
      items {
        value: ID
        label: Name
      }
    }
  }
`;

export const InvoicingModesDropdownQuery = gql`
  query InvoicingModesDropdownQuery($where: InvoicingModeFilterInput) {  
    dropdown: InvoicingModes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodeTypesDropdownQuery = gql`
  query BudgetAccountCodeTypesDropdownQuery($where: AccountCodeTypeFilterInput) {  
    dropdown: AccountCodeTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodesDropdownQuery = gql`
  query BudgetAccountCodesDropdownQuery($where: AccountCodeFilterInput) {  
    dropdown: AccountCodes(where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const BudgetVersionDropdownQuery = gql`
  query BudgetVersionDropdownQuery{  
    dropdown: BudgetVersions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersInOrganisationImpersonationDropdownQuery = gql`
  query UsersInOrganisationImpersonationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      totalCount
      items {
        value: ID
      }
    }
  }
`;

export const UsersInOrganisationDropdownQuery = gql`
  query UsersInOrganisationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const ProjectsDropdownQuery = gql`
  query ProjectsDropdownQuery($where: ProjectFilterInput) {  
    dropdown: Projects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const TasksDropdownQuery = gql`
  query TasksDropdownQuery($where: TaskFilterInput) {  
    dropdown: Tasks(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

/* CUSTOMS - START */
export const PlansDropdownQuery = gql`
  query PlansDropdownQuery($where: PlanFilterInput) {  
    dropdown: Plans(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PhoneNumberTypesDropdownQuery = gql`
  query PhoneNumberTypesDropdownQuery($where: PhoneNumberTypeFilterInput) {  
    dropdown: PhoneNumberTypes(where: $where) {
      items {
        value: ID
        label: Description
        DefaultCost
      }
    }
  }
`;

export const PhoneNumberCarriersDropdownQuery = gql`
  query PhoneNumberCarriersDropdownQuery($where: PhoneNumberCarrierFilterInput) {  
    dropdown: PhoneNumberCarriers(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PhoneNumberCostsDropdownQuery = gql`
  query PhoneNumberCostsDropdownQuery($where: PhoneNumberCostFilterInput) {  
    dropdown: PhoneNumberCosts(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PhoneNumbersDropdownQuery = gql`
  query PhoneNumbersDropdownQuery($where: PhoneNumberFilterInput) {  
    dropdown: PhoneNumbers(where: $where) {
      items {
        value: ID
        label: FriendlyInboundNumber
      }
    }
  }
`;

export const PhoneCallTypesDropdownQuery = gql`
  query PhoneCallTypesDropdownQuery($where: PhoneCallTypeFilterInput) {  
    dropdown: PhoneCallTypes(where: $where) {
      items {
        value: ID
        label: Description
        DefaultCost
      }
    }
  }
`;

export const RoutingProfileActionTypesDropdownQuery = gql`
  query RoutingProfileActionTypesDropdownQuery($where: RoutingProfileActionTypeFilterInput) {  
    dropdown: RoutingProfileActionTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const VerifiedNumbersDropdownQuery = gql`
  query VerifiedNumbersDropdownQuery($where: VerifiedNumberFilterInput) {  
    dropdown: VerifiedNumbers(where: $where) {
      items {
        value: ID
        label: PhoneNumber
      }
    }
  }
`;

export const RoutingProfilesDropdownQuery = gql`
  query RoutingProfilesDropdownQuery($where: RoutingProfileFilterInput) {  
    dropdown: RoutingProfiles(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ScheduleDaysDropdownQuery = gql`
  query ScheduleDaysDropdownQuery($where: ScheduleDayFilterInput) {  
    dropdown: ScheduleDays(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const AffiliateDealsDropdownQuery = gql`
  query AffiliateDealsDropdownQuery($where: AffiliateDealFilterInput) {  
    dropdown: AffiliateDeals(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PromoTypesDropdownQuery = gql`
  query PromoTypesDropdownQuery($where: PromoTypeFilterInput) {  
    dropdown: PromoTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PromoCodesDropdownQuery = gql`
  query PromoCodesDropdownQuery($where: PromoCodeFilterInput) {  
    dropdown: PromoCodes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BrandingTextPagesDropdownQuery = gql`
  query BrandingTextPagesDropdownQuery($where: BrandingTextPageFilterInput) {  
    dropdown: BrandingTextPages(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BrandingTextPageByDescsDropdownQuery = gql`
  query BrandingTextPageByDescsDropdownQuery($where: BrandingTextPageFilterInput) {  
    dropdown: BrandingTextPages(where: $where) {
      items {
        value: Description
        label: Description
      }
    }
  }
`;

export const BrandingTextTypesDropdownQuery = gql`
  query BrandingTextTypesDropdownQuery($where: BrandingTextTypeFilterInput) {  
    dropdown: BrandingTextTypes(where: $where) {
      items {
        value: ID
        label: Description
        BrandingTextPageID
        BrandingTextPage {
          Value {
            Description
          }
        }
      }
    }
  }
`;

export const VoicesDropdownQuery = gql`
  query VoicesDropdownQuery($where: VoiceFilterInput) {  
    dropdown: Voices(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
/* CUSTOMS - END */