import branding from '@config/branding';
import { toAbsoluteUrl } from '@metronic/_helpers';
import React from 'react'

interface AuthLogoProps {
    
}

const AuthLogo: React.FunctionComponent<AuthLogoProps> = () => {

    return (
        <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl(branding.logo.auth)}
        />
    )
}

export default AuthLogo
