import React, { useMemo } from 'react'
import {  useSession } from '@whitecobalt/tungsten/esm/components/Session';
import { useQuery } from '@apollo/client';
import { TimeLogPropType, TimeLogsGridQuery } from '@services/query/TimeLogsQuery';
import Grid from '@whitecobalt/tungsten/esm/components/Grid';
import Icon from '@whitecobalt/tungsten/esm/components/Icon';
import worldTrigger from 'world-trigger'
import './index.scss';
import { useEffectMounted } from '@whitecobalt/tungsten/esm/common/hooks/useEffectMounted';
import { useAuthParamAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthParamAPI';
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI';
import ModalConfirm from '@whitecobalt/tungsten/esm/components/Modal/Confirm';
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster';
import branding from '@config/branding';
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import ModalForm from '@whitecobalt/tungsten/esm/components/Modal/Form';
import { useWorldTrigger } from '@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger';
import { DropdownQueryType, ProjectsDropdownQuery } from '@services/query/DropdownQueries';
import { objectByIndex } from '@whitecobalt/tungsten/esm/common/utils/data';
import Badge from 'react-bootstrap/Badge';
import SelectDropdown from '@whitecobalt/tungsten/esm/components/Fields/SelectDropdown';
import Hours from '@whitecobalt/tungsten/esm/components/Fields/Hours';
import TaskField from '@metronic/layout/components/header/TimeTracker/TaskField';
import { darkenColor } from '@whitecobalt/tungsten/esm/common/utils/colors';
import { emptyArray } from '@whitecobalt/tungsten/esm/common/utils/assets';
import DatePicker from '@whitecobalt/tungsten/esm/components/Fields/Date';
import { useGlobalStoreSelector } from '@services/store/global';

export const convertHoursToSeconds = (hours: number) => Math.floor(hours * 60 * 60)

export const convertHoursToReadable = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds/60) % 60

    const hours = Math.floor(Math.ceil(totalSeconds/60)/60)
    
    const seconds = totalSeconds % 60

    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

// const thisMonth = new Date()

// const firstDayOfMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), 1).toISOString()

// const lastDayOfMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth() + 1, 0, 23, 59, 59).toISOString()

const todayRaw = new Date()

const today = new Date(todayRaw.getFullYear(),  todayRaw.getMonth(), todayRaw.getDate()).toISOString()

const endOfDay = new Date(todayRaw.getFullYear(),  todayRaw.getMonth(), todayRaw.getDate() + 1).toISOString()

const TimeLogs: React.FunctionComponent = () => {
    const [{user}] = useSession()
    const [timeLogID] = useGlobalStoreSelector('timeLogID')
    const requestUpdate = useAuthAPI('/api/TimeLog/update')
    const requestDelete = useAuthParamAPI('/api/TimeLog/{id}')
    const requestTaskTimeLog = useAuthParamAPI('/api/Task/timelog?id={id}', { method: 'POST' })
    const requestProjects = useQuery<DropdownQueryType>(ProjectsDropdownQuery, {
        fetchPolicy: 'no-cache'
    })
    const request = useQuery<{ grid: { items: TimeLogPropType[]} }>(TimeLogsGridQuery, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
            where: {
                UserID: {
                    equals: user?.id
                },
                StartDateTime: {
                    gte: today,
                    lt: endOfDay
                }
            },
            order: {
                EndDateTime: "DESC"
            }
        }
    })

    useWorldTrigger('time.log.reload', () => {
        request?.refetch()
    }, [request?.refetch, timeLogID])

    const handleUpdate = (event: any, item?: TimeLogPropType) => {
        event.preventDefault()
        event.stopPropagation()
        const isNew = typeof item === 'undefined'
        const date = item?.StartDateTime ? new Date(new Date(item?.StartDateTime).toDateString()) : undefined
        const startTime = item?.StartDateTime ? new Date(item?.StartDateTime).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit", second: "2-digit" }): undefined
        const endTime = item?.EndDateTime ? new Date(item?.EndDateTime).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit", second: "2-digit" }): undefined
        
        const recursivePromptForm = () => {
            ModalForm({
                key: "add-edit-timelog",
                title: `${isNew ? 'Add' : 'Edit'} Time Log`,
                titleIcon: `fa fa-${isNew ? 'plus' : 'pen'} fa-lg`,
                body: (
                    <>  
                        {/* <FormManager.Input
                            as={Hours}
                            label="Time"
                            name="editTime"
                            value={Hours.numberToTime(item?.Hours || 0)}
                            withSeconds
                            // description="The hours & minutes you worked on"
                            /> */}
                        <div className="form-group">
                            <label>Time</label>
                            <FormManager.Row>
                                <FormManager.Col sm={4}>
                                    <FormManager.Input 
                                        as={DatePicker}
                                        name="logDate" 
                                        placeholder="01/11/1997"
                                        stripped
                                        value={date} />
                                </FormManager.Col>
                                <FormManager.Col sm={8}>
                                    <FormManager.Row>
                                        <FormManager.Col>
                                            <FormManager.Input 
                                                type="time"
                                                name="timeStart" 
                                                stripped
                                                rules="required"
                                                className="custom-time-field"
                                                value={startTime} />
                                        </FormManager.Col>
                                        <FormManager.Col>
                                            <FormManager.Input 
                                                type="time"
                                                name="timeEnd" 
                                                stripped
                                                rules="required"
                                                className="custom-time-field"
                                                value={endTime}/>
                                        </FormManager.Col>
                                    </FormManager.Row>
                                </FormManager.Col>
                            </FormManager.Row>
                        </div>
                        <FormManager.Input
                            label="What are you working on?"
                            name="description"
                            value={item?.Description}
                            rules="required"
                            length={100}
                            // description="The description of what you are working on"
                            />
                        <FormManager.Input 
                            as={SelectDropdown.Graph}
                            gql={ProjectsDropdownQuery}
                            fetchPolicy="cache-first"
                            label="Project"
                            name="metaData1" 
                            value={item?.MetaData1}
                            clearable={false}
                            rules="required_number"
                            manageOptions={(each: any) => {
                                const color = each.Color ? darkenColor(each.Color, 1) : undefined
                                return {
                                    ...each, 
                                    labelNode: (
                                        <div className="d-flex align-items-center" style={{color: color}}>
                                            <div className="bilot-bilot mr-2" style={{backgroundColor: each.Color || '#555'}}/>{each.label}
                                        </div>
                                    )
                                }
                            }}
                            // description="The project you are working on" 
                            />
                        <TaskField 
                            label="Task"
                            stripped={false}
                            value={item?.MetaData2} 
                            loading={false} />
                         <FormManager.Boolean 
                            name="metaData3" 
                            label="Investigation?"
                            checked={item?.MetaData3} 
                        />
                    </>
                ),
            }).then((event) => {
                
                if (event.isSubmit) {
                    if(event.isReady() === false) {
                        event.removeLoading()
                        recursivePromptForm()
                        return;
                    }
                    
                    const FORM_DATA = event.json();
                    const startDateTime = new Date(`${FORM_DATA.logDate.toDateString()} ${FORM_DATA.timeStart || '00:00:00'}`)
                    const endDateTime = new Date(`${FORM_DATA.logDate.toDateString()} ${FORM_DATA.timeEnd || '00:00:00'}`)
                    if(startDateTime.getTime() > endDateTime.getTime()) {
                        endDateTime.setDate(endDateTime.getDate() + 1)
                    }
                    // const endDateTime = new Date(new Date(item?.StartDateTime!).getTime() + (convertHoursToSeconds((Hours.timeToNumber(FORM_DATA.editTime) as number)) * 1000))
                    const payload = {
                        "timeLogID": item?.ID,
                        "description": FORM_DATA.description,
                        "startDateTime": startDateTime,
                        "endDateTime": endDateTime,
                        "metaData1": FORM_DATA.metaData1 || 0,
                        "metaData2": FORM_DATA.metaData2 || null,
                        "metaData3": FORM_DATA.metaData3,
                        "metaData4": item?.MetaData4
                      }

                    const toast = Toaster(`Saving ${FORM_DATA.description}`);

                    requestUpdate
                        .call({ 
                            data: payload
                        })
                        .then((response) => {
                            if (response.data.success) {
                                event.closeModal();

                                toast.success(`Successfully saved ${FORM_DATA.description}`);
                                worldTrigger.dispatchTrigger('time.log.reload')
                                // request.refetch()
                                if(FORM_DATA.metaData2) {
                                    requestTaskTimeLog.call({id: FORM_DATA.metaData2}).then(() => {
                                        worldTrigger.dispatchTrigger('task.reload')
                                    })
                                }
                            } else {
                                toast.fail(response.data.message || branding.messages.fail);
                                FormManager.setBackendValidation(event, response.data.errors);
                                event.setValidationMessages(response.data.errors?.map((error: any) => error.errorMessage))
                                recursivePromptForm();
                            }
                        })
                        .catch((error) => {
                            recursivePromptForm();
                            event.setError(error?.response?.data?.message || branding.messages.error);
                            toast.error();
                        })
                        .finally(() => {
                            event.removeLoading();
                        });
                    }
                });
        };
        recursivePromptForm();
    };

    const handleDelete = (event: any, item: TimeLogPropType) => {
        event.preventDefault()
        event.stopPropagation()
        const convertedHours = convertHoursToReadable(convertHoursToSeconds(item.Hours))
        ModalConfirm({
            titleIcon: "fa fa-trash",
            title: "Delete Time Log",
            body: (
                <p>You are <b className="text-danger">deleting</b> time log <b className="text-danger">{item.Description}</b> ({convertedHours})</p>
            )
        }).then((confirm) => {
            if(confirm) {
                const toast = Toaster(`Deleting ${item.Description} (${convertedHours})`)
                requestDelete.call({
                    id: item.ID
                }).then((response) => {
                    if(response.data.success === true) {
                        toast.success('Successfully deleted Time Log')
                        worldTrigger.dispatchTrigger('time.log.reload')
                    } else {
                        toast.error(response.data.message || branding.messages.error)
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || branding.messages.error)
                })
            }
        })
    }

    const data = useMemo(() => {
        const data = request.data?.grid.items || []
        return timeLogID ? data?.filter((item) => item.ID !== timeLogID) : data
    }, [timeLogID, request.data?.grid.items])

    const totalHours = useMemo(() => {
        const totalSeconds = convertHoursToSeconds(data.reduce((result, each) => each.Hours + result, 0))

       return convertHoursToReadable(totalSeconds)

    }, [data])

    const projectsByID = useMemo(() => objectByIndex('value', requestProjects.data?.dropdown.items || []), [requestProjects.data])

    return (
        <div id="quick-time-logs">
            <Grid 
                data={requestProjects.loading || request.loading ? emptyArray : data}
                totalCount={data.length || 5}
                defaultPerPage={5}
                totalCountAsPerPage
                showFooter={false}
                facadeLoading={request.loading || requestProjects.loading}
                rowAttr={(row: TimeLogPropType) => ({
                    className: 'clickable',
                    onClick: (event: any) => handleUpdate(event, row)
                })}
                columns={[
                    {
                        label: 'Today',
                        dataIndex: 'Description',
                        render: (cell: string, row: TimeLogPropType) => {
                            // @ts-ignore
                            const color = projectsByID[row.MetaData1]?.Color || '#000'
                            return (
                                <div className="d-flex flex-column">
                                    <div className="time-log-task-title">
                                        {cell}
                                    </div>
                                    {!!row.MetaData1 && (
                                        <div>
                                            <div><div className="bilot-bilot mr-2" style={{backgroundColor: color}}/><span className="mt-2" style={{color: darkenColor(color, 1)}}>{projectsByID[row.MetaData1]?.label || '-'}</span></div>
                                        </div>
                                    )}
                                </div>
                            ) 
                        }
                    },
                    {
                        label: `${totalHours}`,
                        dataIndex: 'Hours',
                        render: (cell: number, row: TimeLogPropType) => {
                            const convertedHours = convertHoursToReadable(convertHoursToSeconds(cell))
                            return (
                                <div className="d-flex flex-column">
                                    <div>
                                        {convertedHours}
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <a href="#" className='mr-3' onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            worldTrigger.dispatchTrigger('time.log.play', { description: row.Description, metaData1: row.MetaData1, metaData2: row.MetaData2, metaData3: row.MetaData3 })
                                        }}>
                                            <Icon name="play" />
                                        </a>
                                        <a href="#" className='mr-3' onClick={(event) => handleUpdate(event, row)}>
                                            <Icon name="pen" />
                                        </a>
                                        <a href="#" onClick={(event) => handleDelete(event, row)}>
                                            <Icon name="trash" />
                                        </a>
                                    </div>
                                </div>
                            )
                        }
                    }
                ]}
                />
        </div>
    )
}

export default TimeLogs