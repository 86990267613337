import { lazy } from "react";
// default Tungsten Pages
export const RegisteredUsers = lazy(() => import('./RegisteredUsers'))
export const Users = lazy(() => import('./Users'))
export const SentEmails = lazy(() => import('./SentEmails'))
export const SentSMS = lazy(() => import('./SentSMS'))
export const SystemSettings = lazy(() => import('./SystemSettings'))
export const Companies = lazy(() => import('./Companies'))
export const Reports = lazy(() => import('./Reports'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Widgets = lazy(() => import('./Widgets'))
export const Invoices = lazy(() => import('./Invoices'))
export const Bills = lazy(() => import('./Bills'))
export const PermissionPreset = lazy(() => import('./PermissionPresets'))
export const EmailTemplates = lazy(() => import('./EmailTemplates'))
export const SMSTemplates = lazy(() => import('./SMSTemplates'))
export const DocumentTemplates = lazy(() => import('./DocumentTemplates'))
export const PaymentAccounts = lazy(() => import('./PaymentAccounts'))
export const PaymentRuns = lazy(() => import('./PaymentRuns'))
export const TimeLogs = lazy(() => import('./TimeLogs'))
export const TimeLogHistory = lazy(() => import('./TimeLogHistory'))
export const TimeLogReport = lazy(() => import('./TimeLogReport'))
export const BackgroundProcesses = lazy(() => import('./BackgroundProcesses'))
export const DisconnectedProcesses = lazy(() => import('./DisconnectedProcesses'))
export const Budget = lazy(() => import('./Budget'))

// customize Pages
export const Customers = lazy(() => import('./CustomPages/Customers'))
export const Affiliates = lazy(() => import('./CustomPages/Affiliates'))
export const MyCompany = lazy(() => import('./CustomPages/MyCompany'))
export const PhoneNumbers = lazy(() => import('./CustomPages/PhoneNumbers'))
export const Plans = lazy(() => import('./CustomPages/Plans'))
export const PhoneCalls = lazy(() => import('./CustomPages/PhoneCalls'))
export const RoutingProfiles = lazy(() => import('./CustomPages/RoutingProfiles'))
export const VerifiedNumbers = lazy(() => import('./CustomPages/VerifiedNumbers'))
export const BrandingConfigs = lazy(() => import('./CustomPages/BrandingConfigs'))
export const Blocklists = lazy(() => import('./CustomPages/Blocklists'))
export const PromoCodes = lazy(() => import('./CustomPages/PromoCodes'))